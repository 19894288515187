<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div class="aside-primary d-flex flex-column align-items-center flex-row-auto">
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps"
        style="height: 528px; overflow: hidden;">
        <!--begin::Nav-->
        <ul class="nav flex-column" role="tablist">
          <!--begin::Item-->
          <router-link to="/dashboard" v-slot="{ href }">
            <li class="nav-item mb-3" data-placement="right" data-container="body" data-boundary="window"
              v-b-tooltip.hover.right="'Dashboard'">
              <a :href="href" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" @click="setActiveTab"
                data-tab="1">
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!--end::Item-->
          <!--begin::Item-->
          <router-link to="/calls" v-slot="{ href }">
            <li class="nav-item mb-3" data-placement="right" data-container="body" data-boundary="window"
              v-b-tooltip.hover.right="'Calls'">
              <a :href="href" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" @click="setActiveTab"
                data-tab="2">
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Files/File-plus.svg" />
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!--end::Item-->
          <!--begin::Item-->
          <router-link to="/messages" v-slot="{ href }" v-if="isAdmin">
            <li class="nav-item mb-3" data-placement="right" data-container="body" data-boundary="window"
              v-b-tooltip.hover.right="'Messages'">
              <a :href="href" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" @click="setActiveTab"
                data-tab="3">
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Home/Library.svg" />
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!--end::Item-->
          <!--begin::Item-->
          <router-link to="/users" v-slot="{ href }" v-if="isAdmin">
            <li class="nav-item mb-3" data-placement="right" data-container="body" data-boundary="window"
              v-b-tooltip.hover.right="'Users'">
              <a :href="href" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" @click="setActiveTab"
                data-tab="4">
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Communication/Group.svg" />
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!--end::Item-->
          <!--begin::Item-->
          <router-link to="/iFrameFileManager" v-slot="{ href }" v-if="isAdmin">
            <li class="nav-item mb-3" data-placement="right" data-container="body" data-boundary="window"
              v-b-tooltip.hover.right="'Files'">
              <a :href="href" target="_blank" class="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab"
                data-tab="5">
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Files/File.svg" />
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!--end::Item-->
          <!--begin::Item-->
          <button class="nav-link btn btn-icon btn-clean btn-lg" v-b-modal.sign-out-modal>
            <li class="nav-item mb-3" data-placement="right" data-container="body" data-boundary="window"
              v-b-tooltip.hover.right="'Logout'">
              <a href="#">
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <inline-svg src="media/svg/icons/Navigation/Sign-out.svg" />
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </button>
          <b-modal id="sign-out-modal" hide-footer hide-header>
            <p class="my-4">Are you sure you want to logout?</p>
            <b-button class="mt-3" @click="onLogout">Sign out</b-button>
          </b-modal>
          <!--end::Item-->
        </ul>
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->
    </div>
    <!--end::Primary-->
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs>div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 0
    };
  },
  components: {
    KTBrand
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });
  },
  methods: {
    setActiveTab(event) {
      if (this.connection) {
        event.preventDefault();
        return;
      }

      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),
    ...mapState({
      user: state => state.auth.user,
      connection: state => state.call.connection
    }),
    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    isAdmin() {
      return this.user && this.user.roleId == 1;
    }
  }
};
</script>
