<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="show = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: "",
      color: "",
      timeout: 3000
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "setSnackbar") {
        this.message = state.snackbar.message;
        this.color = state.snackbar.color;
        this.show = true;
      }
    });
  }
};
</script>
